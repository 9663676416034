import request from '@/utils/request'

export function queryInRect(params) {
  return request({
    url: '/media/queryInRect',
    method: 'get',
    params
  })
}

export function queryHotPage(data) {
  return request({
    url: '/media/queryHotPage',
    method: 'post',
    data
  })
}
