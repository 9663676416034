<template>
  <div id="home">
    <div id="allmap"></div>
    <div @click="changeTheme()" class="btn-dot btn-theme">
      <i class="iconfont icon-palette"></i>
    </div>

    <div @click="loadMore()" class="btn-dot btn-refresh">
      <i class="iconfont icon-refresh"></i>
    </div>
    <modal
      name="media-list"
      :scrollable="true"
      :reset="true"
      height="auto"
      @before-open="beforeOpen"
      @opened="opened"
    >
      <div class="media-item"
        v-for="(media, mediaIndex) in clusterMedias"
        :key="mediaIndex"
        @click="index = mediaIndex"
      >
        <img
            class="media-item-img"
            :src="media.type == 0 ? media.url : media.coverUrl + '?x-oss-process=image/resize,w_400,/quality,q_100'"
          />
          <div v-if="media.type == 1" class="play-icon">
            <i class="iconfont icon-play"/>
          </div>
      </div>
    </modal>

    <gallery :images="images" :index="index" @close="index = null"></gallery>

    <div class="header">
      <i class="iconfont icon-title app-title"></i>
    </div>
    <div class="footer">
      <div class="copyright">&copy; 2021</div>
      <div class="company">上海嘤其鸣网络科技有限公司</div>
      <div class="icp">&nbsp;&nbsp;沪ICP备 2021034677号-1</div>
    </div>
  </div>
</template>
<script>
import VueGallery from "vue-gallery";
import { queryHotPage } from "@/api/media";
import MarkerClusterer from "./MarkerClusterer";

import styleTea from "@/assets/map/style.json";
import styleDark from "@/assets/map/style-dark.json";

const mapStyles = [styleTea, styleDark];

const maplibregl = window.maplibregl;

export default {
  name: "Map",
  components: {
    gallery: VueGallery,
  },
  data() {
    return {
      map: null,
      index: null,
      medias: [],
      markers: [],
      clusterMedias: [],
      images: [],
      themeIndex: 0,
      page: {
        pageNum: 1,
        pageSize: 40
      }
    };
  },
  mounted() {
    this.ready();
  },
  methods: {
    ready: function () {
      const point = {lng:116.404, lat:39.915};

      const mapZoom = localStorage.getItem("mapZoom")
      let mapCenter = JSON.parse(localStorage.getItem("mapCenter"))
      mapCenter = mapCenter != null ? mapCenter : point

      const storeIndex = localStorage.getItem("themeIndex");
      if (storeIndex) {
        this.themeIndex = storeIndex;
      }

      this.map = new maplibregl.Map({
        container: 'allmap',
        style: mapStyles[this.themeIndex],
        center: [mapCenter.lng, mapCenter.lat],
        zoom: mapZoom,
        minZoom: 1.8,
        maxZoom: 22,
        pixelRatio: 4
      })

      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disableRotation();
      this.map.on("moveend", this.moveendCall);
      this.map.on("zoomend", this.zoomendCall);

      // this.map.on('load', () => {
      //   this.map.setLayoutProperty('label_country', 'text-field', ['get','name:zh'])

      //   console.log(this.map.getStyle())
      // })

      this.queryHotPage();
    },
    moveendCall() {
      setTimeout(() => {
        const center = this.map.getCenter()
        localStorage.setItem("mapCenter", JSON.stringify(center))
      }, 500);
    },
    zoomendCall() {
      const zoom = this.map.getZoom();
      setTimeout(() => {
        const center = this.map.getCenter()

        localStorage.setItem("mapZoom", zoom);
        localStorage.setItem("mapCenter", JSON.stringify(center))
      }, 500);
    },
    getBounds() {
      var bounds = this.aMap.getBounds();
      const northeast = bounds.northeast; // 东北
      const southwest = bounds.southwest; // 西南

      const params = {
        bLat: southwest.lat,
        tLat: northeast.lat,
        lLng: southwest.lng,
        rLng: northeast.lng,
      };
      this.queryInRect(params);
    },
    async queryHotPage() {
      const params = { pageNum: this.page.pageNum, pageSize: this.page.pageSize };
      const data = await queryHotPage(params);

      this.medias = data.record

      let markers = [];

      data.record.map((media) => {
        if (!media.longitude && !media.latitude) {
          return;
        }
        const feature = {
          'type': 'Feature',
          'properties': {
            'iconSize': [60, 60]
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [media.longitude, media.latitude]
          },
          url: media.type == 0 ? media.url : media.coverUrl,
          media
        }

        const marker = this.addMarker(feature)

        markers.push(marker)

        // const thumbnail = new ThumbnailOverlay(media, this.thumbnailClick);
        // thumbnails.push(thumbnail);
      })

      if (this.markerClusterer) {
        this.markerClusterer.addMarkers(markers)
      } else {
        this.markerClusterer = new MarkerClusterer(this.map, this.thumbnailClick, { markers: markers });
      }
    },
    thumbnailClick(clusterMedias) {
      // console.log(clusterMedias)
      this.clusterMedias = clusterMedias;
      if (clusterMedias.length > 6) {
        this.$modal.show("media-list");
      } else {
        this.images = []
        this.setGallery();
        this.index = 0;
      }
    },
    addMarker(feature) {
      var el = document.createElement('div');
      el.className = 'thumbnail';

      let img = document.createElement("img");
      let url = `${feature.url}?x-oss-process=image/resize,w_400,/quality,q_100`

      img.src = url

      el.appendChild(img);

      // el.addEventListener('click',  () => {
      //   this.images = []
      //   this.setGallery();
      //   this.index = 0;
      // });

      const marker = new maplibregl.Marker(el)
        .setLngLat(feature.geometry.coordinates)
        .setOffset([30, -30])
        // .addTo(this.map);

        marker.media = feature.media
      
      return marker
    },
    beforeOpen(event) {
      console.log("before-open", event);
      this.images = [];
    },
    opened(event) {
      console.log("opened", event);
      
      this.setGallery();
      // const num = this.clusterMedias.length
      // event.ref.style.width = 75 * (num > 8 ? 8 : num) + 'px'
      event.ref.style.width = "600px";
    },
    setGallery() {
      console.log('this.clusterMedias: ', this.clusterMedias)
      this.clusterMedias.map((v) => {
        const itemJson = {
          type: "image/jpeg",
          href: v.url,
        };

        if (v.type == 0) {
          itemJson[
            "thumbnail"
          ] = `${v.url}'?x-oss-process=image/resize,w_400,/quality,q_100'`;
        } else {
          itemJson["type"] = `video/mp4`;
          itemJson["poster"] = `${v.coverUrl}`;
        }

        this.images.push(itemJson);
      });
    },
    changeTheme() {
      this.themeIndex += 1;
      this.themeIndex %= 2;
      localStorage.setItem("themeIndex", this.themeIndex);

      this.map.setStyle(mapStyles[this.themeIndex])
    },
    loadMore() {
      if (this.medias.length < this.page.pageSize) {
        this.$msg('没有更多数据！')
        this.page.pageNum = 1
      } else {
        // const overlays = this.bMap.getOverlays()
        // overlays.map(v => {
        //   this.bMap.removeOverlay(v)
        // })
        this.page.pageNum += 1
      }
      
      this.markerClusterer.clearMarkers()

      this.queryHotPage() 
    },
    // goAbout() {
    //   this.$router.push({ name: 'about' })
    // }
  },
};
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  height: 100%;
  position: relative;
}
.top-bar {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#allmap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.btn-dot {
  z-index: 100;
  position: absolute;
  cursor: pointer;
  margin: 1.6rem 1.6rem 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.2)
  );
  .iconfont {
    color: #fff;
    font-size: 1.6rem;
  }
}
.btn-theme {
  right: 0;
  bottom: 110px;
  background-image: linear-gradient(
    45deg,
    rgba(255, 123, 172, 0.31),
    rgba(255, 123, 172, 0.94)
  );
}
.btn-refresh {
  right: 0;
  bottom: 5rem;
}
.media-list {
  background: #000;
}
.media-item {
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0.05rem;
    box-sizing: border-box;
    object-fit: cover;
    cursor: pointer;
  }
  .play-icon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 1.2rem;
      color: #fff;
    }
  }
}
.header {
  padding: 1rem 1.5rem;
  position: absolute;
  z-index: 1001;
  background-color: hsla(0,0%,100%,.5);
  color: #fff;
  &-r {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .app-title {
    font-size: 2rem;
    color: #666;
  }
  .about-us {
    color: #fff;
    text-decoration: none;
    margin-right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer {
  display: none;
  // display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}
.app-link {
  display: flex;
  margin-right: 4%;
  &-item {
    height: 2.4rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2.4rem;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
  .apple {
    margin-right: 2rem;
  }
  i {
    font-size: 1.8rem;
  }
  span {
    display: inline-block;
    margin-left: 0.4rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 50rem) {
  .header .about-us {
    display: none;
  }
  .app-link {
    &-item {
      span {
        display: none;
      }
    }
  }
}
</style>>
<style lang="scss">
#home .vm--modal {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>