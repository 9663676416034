// http://api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer.js

const maplibregl = window.maplibregl;

/**
 * MarkerClusterer
 * @class 用来解决加载大量点要素到地图上产生覆盖现象的问题，并提高性能
 * @constructor
 * @param {Map} map 地图的一个实例。
 * @param {Json Object} options 可选参数，可选项包括：<br />
 *    markers {Array<Marker>} 要聚合的标记数组<br />
 *    girdSize {Number} 聚合计算时网格的像素大小，默认60<br />
 *    maxZoom {Number} 最大的聚合级别，大于该级别就不进行相应的聚合<br />
 *    minClusterSize {Number} 最小的聚合数量，小于该数量的不能成为一个聚合，默认为2<br />
 *    isAverangeCenter {Boolean} 聚合点的落脚位置是否是所有聚合在内点的平均值，默认为否，落脚在聚合内的第一个点<br />
 *    styles {Array<IconStyle>} 自定义聚合后的图标风格，请参考TextIconOverlay类<br />
 */
const MarkerClusterer = function (map, thumbnailClick, options) {
  if (!map) {
    return;
  }

  // this._point = new BMapGL.Point(112, 43);
  this._map = map;
  this._markers = [];
  this._clusters = [];

  let opts = options || {};
  this._gridSize = opts["gridSize"] || 60;
  this._maxZoom = opts["maxZoom"] || 18;
  this._minClusterSize = opts["minClusterSize"] || 2;
  this._isAverageCenter = false;
  if (opts['isAverageCenter'] != undefined) {
    this._isAverageCenter = opts['isAverageCenter'];
  }
  this._styles = opts["styles"] || [];

  this._map.on("zoomend", () => {
    this._redraw();
  });

  this._thumbnailClick = thumbnailClick

  this._map.on("moveend", () => {
    this._redraw();
  });

  let mkrs = opts["markers"];

  isArray(mkrs) && this.addMarkers(mkrs);
};

/**
   * 判断给定的对象是否为数组
   * @param {Object} source 要测试的对象
   *
   * @return {Boolean} 如果是数组返回true，否则返回false
*/
let isArray = function (source) {
  return '[object Array]' === Object.prototype.toString.call(source);
};

/**
 * 返回item在source中的索引位置
 * @param {Object} item 要测试的对象
 * @param {Array} source 数组
 *
 * @return {Number} 如果在数组内，返回索引，否则返回-1
 */
let indexOf = function (item, source) {
  let index = -1;
  if (isArray(source)) {
    if (source.indexOf) {
      index = source.indexOf(item);
    } else {
      source.map((m, i) => {
        if (m === item) {
          index = i;
        }
      })
    }
  }
  return index;
};

/** 
 * 添加要聚合的标记数组。
 * @param {Array<Marker>} markers 要聚合的标记数组
 *
 * @return 无返回值。
 */
// MarkerClusterer初始化调用一次
MarkerClusterer.prototype.addMarkers = function (markers) {
  this._clusters = []
  this._markers = []

  markers.map(v => this._pushMarkerTo(v))
  this._createClusters();
};

/**
 * 把一个标记添加到要聚合的标记数组中
 * @param {BMapGL.Marker} marker 要添加的标记
 *
 * @return 无返回值。
 */
MarkerClusterer.prototype._pushMarkerTo = function (marker) {
  let index = indexOf(marker, this._markers);
  if (index === -1) {
    marker.isInCluster = false;
    this._markers.push(marker);//Marker拖放后enableDragging不做变化，忽略
  }
};

/**
 * 根据所给定的标记，创建聚合点
 * @return 无返回值
 */
MarkerClusterer.prototype._createClusters = function () {
  let mapBounds = this._map.getBounds();
  let extendedBounds = getExtendedBounds(this._map, mapBounds, this._gridSize);

  this._markers.map(marker => {
    if (!marker.isInCluster && this._containsPoint(extendedBounds, marker.getLngLat())) {
      this._addToClosestCluster(marker);
    }
  })

  this._clusters.forEach(cluster => {
    const marker = cluster._markers[0]
    const medias = []
    cluster._markers.forEach(v => {
      medias.push(v.media)
    })
    marker.getElement().onclick = null
    marker.getElement().addEventListener("click", () => {
      this._thumbnailClick(medias)
    });
  });
};

/**
 * 重新生成，比如改变了属性等
 * @return 无返回值
 */
MarkerClusterer.prototype._redraw = function () {
  this._clearLastClusters();
  this._createClusters();
};

/**
 * 清除上一次的聚合的结果
 * @return 无返回值。
 */
MarkerClusterer.prototype._clearLastClusters = function () {
  this._clusters.map(cluster => {
    cluster.remove();
  })
  this._clusters = [];//置空Cluster数组
  this._removeMarkersFromCluster();//把Marker的cluster标记设为false
};

/**
 * 清除某个聚合中的所有标记
 * @return 无返回值
 */
MarkerClusterer.prototype._removeMarkersFromCluster = function () {
  this._markers.map(marker => {
    marker.isInCluster = false;
  })
};

/**
 * 根据标记的位置，把它添加到最近的聚合中
 * @param {BMap.Marker} marker 要进行聚合的单个标记
 *
 * @return 无返回值。
 */
MarkerClusterer.prototype._addToClosestCluster = function (marker) {
  let distance = 4000000;
  let clusterToAddTo = null;
  // let position = marker.getLngLat();
  // 遍历之前加入的簇
  this._clusters.map(cluster => {
    let center = cluster.getCenter();
    if (center) {
      const { lng, lat } = marker.getLngLat()

      let centerLngLat = new maplibregl.LngLat(center.lng, center.lat);
      let markerLngLat = new maplibregl.LngLat(lng, lat);
      // 计算后续加入的marker属不属于之前创建的簇。遍历之前的簇集合
      let d = centerLngLat.distanceTo(markerLngLat);
      if (d < distance) {
        distance = d;
        // 当前marker属于的簇 
        clusterToAddTo = cluster;
      }
    }
  })

  if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
    clusterToAddTo.addMarker(marker);
  } else {
    let cluster = new Cluster(this);
    cluster.addMarker(marker);
    this._clusters.push(cluster);  // 向簇集合中放入一个簇
  }
};


/**
 * 获取聚合的Map实例。
 * @return {Map} Map的示例。
 */
MarkerClusterer.prototype.getMap = function () {
  return this._map;
};

/**
 * 获取所有的标记数组。
 * @return {Array<Marker>} 标记数组。
 */
MarkerClusterer.prototype.getMarkers = function () {
  return this._markers;
};

/**
 * 清空Marker。
 * @return {Array<Marker>} 清空Marker。
 */
MarkerClusterer.prototype.clearMarkers = function () {
  // return this._markers;
  this._markers.map(v => v.remove());
};

/**
 * 获取单个聚合的最小数量。
 * @return {Number} 单个聚合的最小数量。
 */
MarkerClusterer.prototype.getMinClusterSize = function () {
  return this._minClusterSize;
};

/**
 * 获取网格大小
 * @return {Number} 网格大小
 */
MarkerClusterer.prototype.getGridSize = function () {
  return this._gridSize;
};

/**
 * 获取单个聚合的落脚点是否是聚合内所有标记的平均中心。
 * @return {Boolean} true或false。
 */
MarkerClusterer.prototype.isAverageCenter = function () {
  return this._isAverageCenter;
};

/**
 * 获取thumbnailClick
 * @return {Function} 
 */
MarkerClusterer.prototype.getThumbnailClick = function () {
  return this._thumbnailClick;
};
 
/**
 * 获取一个扩展的视图范围，把上下左右都扩大一样的像素值。
 * @param {Map} map BMap.Map的实例化对象
 * @param {BMapGL.Bounds} bounds BMap.Bounds的实例化对象
 * @param {Number} gridSize 要扩大的像素值
 *
 * @return {BMapGL.Bounds} 返回扩大后的视图范围。
 */

let getExtendedBounds = function (map, bounds, gridSize) {
  //     ne 东北
  // sw     西南
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  const { lngPerPixel, latPerPixel } = getLngLatPerPixel(map)

  let newSW = new maplibregl.LngLat(sw.lng - gridSize*lngPerPixel, sw.lat - gridSize*latPerPixel);
  let newNE = new maplibregl.LngLat(ne.lng + gridSize*lngPerPixel, ne.lat + gridSize*latPerPixel);

  return new maplibregl.LngLatBounds(newSW, newNE);
};

let getLngLatPerPixel = function (map) {
  let bounds = map.getBounds()
  let style = map.getCanvas().style
  let canvasW, canvasH

  if (style && style.width && style.height) {
    if (style.width.includes('px')) {
      canvasW = style.width.split('px')[0]
      canvasH = style.height.split('px')[0]
    } else {  
      canvasW = style.width
      canvasH = style.height
    }
    
  } else {
    canvasW = style.width
    canvasH = style.height
  }

  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  let lngPerPixel = Math.abs(sw.lng - ne.lng)/canvasW
  let latPerPixel = Math.abs(sw.lat - ne.lat)/canvasH

  return { lngPerPixel, latPerPixel }
}

MarkerClusterer.prototype._containsPoint = function (extendedBounds, point) {
  let bLat = extendedBounds.getSouthWest().lat
  let tLat = extendedBounds.getNorthEast().lat
  let lLng = extendedBounds.getSouthWest().lng
  let rLng = extendedBounds.getNorthEast().lng
  let isContainedLng = false
  let isContainedLat = false

  //============== 东半球、西半球

  if (lLng > 0 && rLng > 0) {
    // 0 < lLng --> rLng  东半球
    if (lLng < rLng) {
      isContainedLng = point.lng >= lLng && point.lng <= rLng
    }
    // 跨度超过180度  东半球部分 + (整个西半球 + 东半球部分)
    // lLng --> 180 || -180 --> 0 -> rLng
    if (lLng > rLng) {
      isContainedLng = (point.lng >= lLng && point.lng <= 180) || (point.lng >= -180 && point.lng <= rLng)
    }
  }

  if (lLng < 0 && rLng < 0) {
    // lLng --> rLng  < 0  西半球
    if (lLng < rLng) {
      isContainedLng = point.lng >= lLng && point.lng <= rLng
    }
    // 跨度超过180度  (西半球部分 + 整个东半球) + 西半球部分
    // lLng --> 0 --> 180 || -180 --> rLng
    if (lLng > rLng) {
      isContainedLng = (point.lng >= lLng && point.lng <= 180) || (point.lng >= -180 && point.lng <= rLng)
    }
  }
  // 0 < lLng --> 180 || -180 --> rLng < 0 （东半球部分 + 西半球部分）连着
  if (lLng > 0 && rLng < 0) {
    isContainedLng = (point.lng >= lLng && point.lng <= 180) || (point.lng >= -180 && point.lng <= rLng)
  }
  // lLng --> 0 --> rLng  （西半球半分 + 东半球部分）连着
  if (lLng < 0 && rLng > 0) {
    isContainedLng = point.lng >= lLng && point.lng <= rLng
  }

  //============== 北半球、南半球

  if (bLat > 0 && tLat > 0) {
    // 0 < bLat --> tLat  北半球
    if (bLat < tLat) {
      isContainedLat = point.lat >= bLat && point.lat <= tLat
    }
    // 跨度超过90度 北球部分 +（整个南半球 + 北半球部分）
    // bLat --> 90 || -90 --> 0 --> tLat
    if (bLat > tLat) {
      isContainedLat = (point.lat >= bLat && point.lat <= 90) || (point.lat >= -90 && point.lat <= tLat)
    }
  }

  if (bLat < 0 && tLat < 0) {
    // bLat --> tLat < 0 南半球
    if (bLat < tLat) {
      isContainedLat = point.lat >= bLat && point.lat <= tLat
    }
    // 跨度超过90度（南球部分 + 整个北半球）+ 南半球部分
    // bLat --> 0 --> 90 ||  0 --> tLat
    if (bLat > tLat) {
      isContainedLat = (point.lat >= bLat && point.lat <= 90) || (point.lat >= 0 && point.lat <= tLat)
    }
  }

  // 0 < bLat --> 90 || -90 --> tLat < 0 （北半球部分 + 南半球部分）连着
  if (bLat > 0 && tLat < 0) {
    isContainedLat = (point.lat >= bLat && point.lat <= 90) || (point.lat >= -90 && point.lat <= tLat)
  }
  // bLat --> 0 --> tLat  （南半球部分 + 北半球部分）连着
  if (bLat < 0 && tLat > 0) {
    isContainedLat = point.lat >= bLat && point.lat <= tLat
  }

  return isContainedLng && isContainedLat;
}


/**
 * 设置单个聚合的最小数量。
 * @param {Number} size 单个聚合的最小数量。
 * @return 无返回值。
 */
MarkerClusterer.prototype.setMinClusterSize = function (size) {
  this._minClusterSize = size;
  this._redraw();
};

/**
 * @ignore
 * Cluster
 * @class 表示一个聚合对象，该聚合，包含有N个标记，这N个标记组成的范围，并有予以显示在Map上的TextIconOverlay等。
 * @constructor
 * @param {MarkerClusterer} markerClusterer 一个标记聚合器示例。
 */
function Cluster(markerClusterer) {
  this._markerClusterer = markerClusterer;
  this._map = markerClusterer.getMap();
  this._minClusterSize = markerClusterer.getMinClusterSize();
  this._isAverageCenter = markerClusterer.isAverageCenter();
  this._center = null;//落脚位置
  this._markers = [];//这个Cluster中所包含的markers
  this._gridBounds = null;//以中心点为准，向四边扩大gridSize个像素的范围，也即网格范围
  this._isReal = false; //真的是个聚合

  // this._clusterMarker = new BMapLib.TextIconOverlay(this._center, this._markers.length, {"styles":this._markerClusterer.getStyles()});
  //this._map.addOverlay(this._clusterMarker);
}

/**
 * 向该聚合添加一个标记。
 * @param {Marker} marker 要添加的标记。
 * @return 无返回值。
 */
Cluster.prototype.addMarker = function (marker) {
  if (this.isMarkerInCluster(marker)) {
    return false;
  }//也可用marker.isInCluster判断,外面判断OK，这里基本不会命中

  if (!this._center) {
    this._center = marker.getLngLat();
    this.updateGridBounds();//
  } else {
    if (this._isAverageCenter) {
      let l = this._markers.length + 1;
      let lat = (this._center.lat * (l - 1) + marker.getLngLat().lat) / l;
      let lng = (this._center.lng * (l - 1) + marker.getLngLat().lng) / l;
      this._center = { lng, lat }
      this.updateGridBounds();
    } //计算新的Cent
  }
  marker.isInCluster = true;
  this._markers.push(marker);


  let medias = this._markers.map(marker => {
    return marker.media
  })

  // 不足2个的时候，添加。等于2个的时候移除，然后大于2个，不不处理了，后续工作取第一个作为封面
  if (medias.length < this._minClusterSize) {
    marker.addTo(this._map);

    let thumbnailNumEle = this._markers[0].getElement().querySelector('#thumbnail-num')
    if (thumbnailNumEle) {
      thumbnailNumEle.parentNode.removeChild(thumbnailNumEle)
    }
  } else {
    let numEle = this._thumbnailNum = document.createElement("div");
    numEle.setAttribute("id","thumbnail-num");
    numEle.setAttribute("class","thumbnail-num");
    numEle.innerText = this._markers.length
  
    let thumbnailNumEle = this._markers[0].getElement().querySelector('#thumbnail-num')
    if (thumbnailNumEle) {
      thumbnailNumEle.parentNode.removeChild(thumbnailNumEle)
    }
  
    this._markers[0].getElement().appendChild(numEle);
  }
  
  this._isReal = true;
  return true;
};

/**
 * 删除该聚合。
 * @return 无返回值。
 */
Cluster.prototype.remove = function () {
  this._markers.map(marker => {
    marker.remove()
  })
  //清除散的标记点
  // this._map.removeOverlay(this._clusterMarker);
  this._markers.length = 0;
  delete this._markers;
}

/**
 * 判断一个标记是否在该聚合中。
 * @param {Marker} marker 要判断的标记。
 * @return {Boolean} true或false。
 */
Cluster.prototype.isMarkerInCluster = function (marker) {
  if (this._markers.indexOf) {
    return this._markers.indexOf(marker) != -1;
  } else {
    this._markers.map(m => {
      if (m === marker) {
        return true;
      }
    })
  }
  return false;
};

/**
 * 判断一个标记是否在该聚合网格范围中。
 * @param {Marker} marker 要判断的标记。
 * @return {Boolean} true或false。
 */
Cluster.prototype.isMarkerInClusterBounds = function (marker) {
  const { lng, lat } = marker.getLngLat()
  return this._gridBounds.contains(new maplibregl.LngLat(lng, lat));
};

/**
 * 更新该聚合的网格范围。
 * @return 无返回值。
 */
Cluster.prototype.updateGridBounds = function () {
  const { lng, lat } = this._center
  var sw = new maplibregl.LngLat(lng, lat);
  var ne = new maplibregl.LngLat(lng, lat);
  let bounds = new maplibregl.LngLatBounds(sw, ne);

  this._gridBounds = getExtendedBounds(this._map, bounds, this._markerClusterer.getGridSize());
};

/**
 * 获取聚合的center。
 * @return {Map} Map的示例。
 */
Cluster.prototype.getCenter = function () {
  return this._center;
};

export default MarkerClusterer